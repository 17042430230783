import React from 'react'
import SEO from '../components/seo'
import Title from '../components/title'
import Header from '../components/header'
import Container from '../components/container'
import Layout from '../layout'
import P from '../components/paragraph'
import styled from 'styled-components'
import { fluidRange } from 'polished'

const StyledTitle = styled(Title)`
  margin-bottom: -10px;
  margin-top: 50px;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
  ${fluidRange({
      prop: 'font-size',
      fromSize: '18px',
      toSize: '25px'
    },
    '300px',
    '1600px'
  )}
`

const PrivacyPage = () => (
  <Layout>
    <SEO
      title='Privacy Policy'
      description={`
        Valorem is a celebration of beauty without boundaries; a distinctively
        individual approach, totally in tune with today's ambitious niche
        perfume brands.
      `}
    />

    <Header />

    <Container width={800} css='padding-top: 30px;'>
      <Title noMargin>
        <h1>Privacy Policy</h1>
      </Title>
      <P>
        This privacy policy is for this website; valoremdistribution.com and
        governs the privacy of its users who choose to use it. It explains how
        we comply with the GDPR (General Data Protection Regulation), the DPA
        (Data Protection Act) [pre GDPR enforcement] and the PECR (Privacy and
        Electronic Communications Regulations).
      </P>
      <P>
        This policy will explain areas of this website that may affect your
        privacy and personal details, how we process, collect, manage and store
        those details and how your rights under the GDPR, DPA & PECR are adhere
        to. Further explanations may be provided for specific pages or features
        of this website in order to help you understand how we, this website and
        its third parties (if any) interact with you and your computer / device
        in order to serve it to you.
      </P>
      <P>
        Any questions regarding this Policy and our privacy practices should be
        sent by post to: VDL Unit 1 Quadrant Court, Crossways Business Park,
        Greenhithe, DA9 9AY.  Or email info@valoremdistrubution.com
      </P>
      <StyledTitle>
        <h2>The DPA & GDPR May 2018</h2>
      </StyledTitle>
      <P>
        We and this website complies to the DPA (Data Protection Act 1998) and
        already complies to the GDPR (General Data Protection Regulation) which
        comes into affect from May 2018. We will update this policy accordingly
        after the completion of the UK’s exit from the European Union.
      </P>
      <StyledTitle>
        <h2>Who are we?</h2>
      </StyledTitle>
      <P>
        Valorem Distribution is a distribution service for existing beauty
        brands with a global reach.  Offering a variety of services including
        third party logistics, as well as bespoke solutions for new and emerging
        brands.
      </P>
      <P>
        Our registered address is Lynton House, 7-12 Tavistock Square, London,
        WC1H 9LT
      </P>
      <StyledTitle>
        <h2>
          What do we need to collect, why, and what do we do with this
          information?
        </h2>
      </StyledTitle>
      <P>
        In order to process your enquiry, when you fill in our contact form we
        will send your form details to our shared mailbox which resides in
        Google’s Gmail email servers. Your details are not saved in any other
        databases we control. We also attach to the email the order of pages you
        have visited on our website, which helps us improve the user experience
        of our website.
      </P>
      <P>
        We do not pass your information onto any third party’s, and will only
        use this information internally in the short term for making contact
        with yourself to respond to your request.
      </P>
      <P>
        If you would like to request any information held about yourself please
        contact our data protection officer info@valoremdistribution.com and we
        will be able to provide your information back in the digital format it
        was received (email).
      </P>
      <StyledTitle>
        <h2>Use of Cookies</h2>
      </StyledTitle>
      <P>
        This website uses cookies to better the users experience while visiting
        the website. As required by legislation, where applicable this website
        uses a cookie control system, allowing the user to give explicit
        permission or to deny the use of /saving of cookies on their computer /
        device.
      </P>
      <P>
        What are cookies? Cookies are small files saved to the user’s computers
        hard drive that track, save and store information about the user’s
        interactions and usage of the website. This allows the website, through
        its server to provide the users with a tailored experience within this
        website. Users are advised that if they wish to deny the use and saving
        of cookies from this website on to their computers hard drive they
        should take necessary steps within their web browsers security settings
        to block all cookies from this website and its external serving vendors
        or use the cookie control system if available upon their first visit.
      </P>
      <StyledTitle>
        <h2>Website Visitor Tracking</h2>
      </StyledTitle>
      <P>
        This website uses tracking software to monitor its visitors to better
        understand how they use it. The software will save a cookie to your
        computers hard drive in order to track and monitor your engagement and
        usage of the website, but will not store, save or collect personal
        information.
      </P>
      <StyledTitle>
        <h2>Downloads & Media Files</h2>
      </StyledTitle>
      <P>
        Any downloadable documents, files or media made available on this
        website are provided to users at their own risk. While all precautions
        have been undertaken to ensure only genuine downloads are available
        users are advised to verify their authenticity using third party anti
        virus software or similar applications. We accept no responsibility for
        third party downloads and downloads provided by external third party
        websites and advise users to verify their authenticity using third party
        anti virus software or similar applications.
      </P>
      <StyledTitle>
        <h2>Contact & Communication With us</h2>
      </StyledTitle>
      <P>
        Users contacting this us through this website do so at their own
        discretion and provide any such personal details requested at their own
        risk. Your personal information is kept private and stored securely
        until a time it is no longer required or has no use.
      </P>
      <P>
        Where we have clearly stated and made you aware of the fact, and where
        you have given your express permission, we may use your details to send
        you products/services information through a mailing list system. This is
        done in accordance with the regulations named in ‘The policy’ above.
      </P>
      <StyledTitle>
        <h2>Email Mailing List & Marketing Messages</h2>
      </StyledTitle>
      <P>
        We operate an email mailing list program, used to inform subscribers
        about products, services and/or news we supply/publish. Users can
        subscribe through an online automated process where they have given
        their explicit permission. Subscriber personal details are collected,
        processed, managed and stored in accordance with the regulations named
        in ‘The policy’ above. Subscribers can unsubscribe at any time through
        an automated online service, or if not available, other means as
        detailed in the footer of sent marketing messages. The type and content
        of marketing messages subscribers receive, and if it may contain third
        party content, is clearly outlined at the point of subscription.
      </P>
      <P>
        Email marketing messages may contain tracking beacons / tracked
        clickable links or similar server technologies in order to track
        subscriber activity within email marketing messages. Where used, such
        marketing messages may record a range of subscriber data relating to
        engagement, geographic, demographics and already stored subscriber data.
      </P>
      <P>
        Our EMS (email marketing service) provider is; MailChimp and you can
        read their privacy policy in the resources section.
      </P>
      <StyledTitle>
        <h2>External Website Links & Third Parties</h2>
      </StyledTitle>
      <P>
        Although we only looks to include quality, safe and relevant external
        links, users are advised to adopt a policy of caution before clicking
        any external web links mentioned throughout this website.
      </P>
      <P>
        Shortened URL’s; URL shortening is a technique used on the web to
        shorten URL’s (Uniform Resource Locators) to something substantially
        shorter. This technique is especially used in social media. Users should
        take caution before clicking on shortened URL links and verify their
        authenticity before proceeding.
      </P>
      <P>
        We cannot guarantee or verify the contents of any externally linked
        website despite our best efforts. Users should therefore note they click
        on external links at their own risk and we cannot be held liable for any
        damages or implications caused by visiting any external links mentioned.
      </P>
      <StyledTitle>
        <h2>Social Media Policy & Usage</h2>
      </StyledTitle>
      <P>
        We adopt a Social Media Policy to ensure our business and our staff
        conduct themselves accordingly online. While we may have official
        profiles on social media platforms users are advised to verify
        authenticity of such profiles before engaging with, or sharing
        information with such profiles. We will never ask for user passwords or
        personal details on social media platforms. Users are advised to conduct
        themselves appropriately when engaging with us on social media.
      </P>
      <P>
        There may be instances where our website features social sharing
        buttons, which help share web content directly from web pages to the
        respective social media platforms. You use social sharing buttons at
        your own discretion and accept that doing so may publish content to your
        social media profile feed or page.
      </P>
    </Container>
  </Layout>
)

export default PrivacyPage
